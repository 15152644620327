import {
  ContentPageStoryblok,
  HeroImageSectionStoryblok,
  HeroSectionStoryblok,
  SectionStoryblok,
} from 'types/storyblok-types'
import {StoryblokComponent} from '@storyblok/react'
import ServicePointLocator from './ServicePointLocator/ServicePointLocator'
import {useRouter} from 'next/router'
import {useEffect} from 'react'
import {Analytics} from 'lib/analytics'
import {Segment} from 'util/slugHelpers/segment'

type HomepageProps = {
  blok: ContentPageStoryblok
  publishedAt: string
}

const Homepage = ({blok}: HomepageProps) => {
  const router = useRouter()

  useEffect(() => {
    const path_components = router.asPath.split('/')
    let page_name = null

    switch (path_components[path_components.length - 1]) {
      case '':
        page_name = 'homepage particulieren'
        break
      case Segment.particulier:
        page_name = 'homepage particulieren'
        break
      case Segment.professional || Segment.professionnel:
        page_name = 'homepage professionals'
        break
    }

    const is_form = router.asPath.includes('forms')
    if (is_form) {
      // The page view will be handled in the form component as we don't have
      // the necessary details.
      return
    }

    Analytics.getInstance().sendPageView(
      page_name ? page_name : blok.title,
      page_name ? ['home'] : [],
      200,
      page_name ? 'home' : 'content detail',
      router.locale,
    )
  }, [router.asPath, router.locale, blok.title])

  return (
    <>
      <div className="flex flex-col">
        {blok.content
          ? blok.content.map((nestedBlok: any, index) => {
              const arrayOfHeroIndexesWithRoundedBorder: number[] = []

              const pushIndexToArray = (i: number) => {
                arrayOfHeroIndexesWithRoundedBorder.push(i)
              }

              blok.content?.forEach(
                (
                  content:
                    | SectionStoryblok
                    | HeroSectionStoryblok
                    | HeroImageSectionStoryblok,
                  index,
                ) => {
                  if (
                    (content.component === 'hero_section' ||
                      content.component === 'hero_image_section') &&
                    content.bottom_rounded_overlay === true
                  ) {
                    pushIndexToArray(index + 1)
                  }
                },
              )
              return (
                <StoryblokComponent
                  blok={nestedBlok}
                  key={nestedBlok._uid}
                  sectionAfterRoundedHero={arrayOfHeroIndexesWithRoundedBorder.includes(
                    index,
                  )}
                />
              )
            })
          : null}

        {blok['servicePointLocator']?.story ? (
          <ServicePointLocator
            blok={blok['servicePointLocator'].story.content}
          />
        ) : null}
      </div>
    </>
  )
}

export default Homepage
